import React, { useState } from "react";
import axios from "axios";
import PageTransition from "gatsby-plugin-page-transitions";
import {
  Jumbotron,
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Modal,
  Form
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import SEO from "../components/Seo/Seo";
import { P, H1, H2, Span } from "../components/Typography/Typography";
import talksLogo from "../images/sf-talks-logo.png";
import logo from "../images/logo.svg";
import { Link } from "gatsby";

const IndividualsPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [showCustomAmount, setShowCustomAmount] = useState(false);
  const [donateAmount, setDonateAmount] = useState();
  const [validated, setValidated] = useState(false);

  const handleModal = () => setShowModal(!showModal);

  const handleDetalii = event => {
    setShowCustomAmount(false);
    setShowDetailsForm(true);
    setDonateAmount(event.target.value);
  };

  const handleDonateAmount = event => {
    setDonateAmount(event.target.value);
  };

  const handleCustomAmount = () => {
    setShowCustomAmount(true);
    setShowDetailsForm(true);
    setDonateAmount();
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    const form = e.target;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      axios({
        method: "post",
        url: "/donatii/plateste.php",
        data: new FormData(form)
      })
        .then(r => {
          document.write(r.data);
        })
        .catch(r => {
          console.log(r);
        });
    }

    setValidated(true);
  };

  return (
    <>
      <PageTransition>
        <Layout>
          <SEO title="Individuals" />
          <Container>
            <Jumbotron
              as="header"
              className="sf-community d-flex align-items-center"
            >
              <Row>
                <Col lg={8}>
                  <H1>Be part of our developer community</H1>
                  <P>
                    If you found the community fun & engaging and feel that you
                    would like to help it growth below you will find a few ways
                    to do so. You can be a speaker in our next talk, donate or
                    become a volunteer by bringing Talks by Softbinator in your
                    city.
                  </P>
                </Col>
              </Row>
            </Jumbotron>
          </Container>

          <Section className="sf-make-donation">
            <Container>
              <Row className="align-items-center pb-5">
                <Col md={6}>
                  <H2>Make a donation</H2>
                  <P>
                    Bring your contribution and help us to continue our work,
                    become sustainable and grow our community.
                    <Button
                      className="donate"
                      variant="primary"
                      onClick={handleModal}
                    >
                      Donate
                    </Button>
                  </P>
                </Col>
                <Col md={6}>
                  <div className="talks d-flex justify-content-center align-items-center">
                    <Image src={talksLogo} alt="Softbinator Talks" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-5 mb-3">
                <Col lg={6}>
                  <Card className="be-speaker px-3 py-3">
                    <Card.Body>
                      <Card.Title>Be a speaker</Card.Title>
                      <Card.Text>
                        Are you an IT Specialist that has a lot to say? Are you
                        working on something exciting and you want to share with
                        your fellow programmers? Are you developing a start-up
                        in the tech area and you want to promote it?
                      </Card.Text>
                      <Button as={Link} to="/contact" variant="outline-dark">
                        Contact us
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="be-volunteer px-3 py-3">
                    <Card.Body>
                      <Card.Title>Be a volunteer</Card.Title>
                      <Card.Text>
                        We are present in Bucharest and Constanta. Be a pioneer
                        and help us to organise and improve our events in your
                        city. Bring developers together and create a community
                        in your city that you can be proud of. Ready to be a
                        volunteer?
                      </Card.Text>
                      <Button as={Link} to="/contact" variant="outline-dark">
                        Contact us
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Section>
        </Layout>
      </PageTransition>

      <Modal show={showModal} onHide={handleModal} backdrop="static">
        <Modal.Header closeButton />
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={7}>
                <H2>Make a donation</H2>
                <P>
                  Bring your contribution and help us to continue our work,
                  become sustainable and grow our community.
                </P>
              </Col>
              <Col lg={5}>
                <Image src={logo} className="img-fluid" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form
                  className="doneaza"
                  onSubmit={handleOnSubmit}
                  noValidate
                  validated={validated}
                  name="doneaza"
                >
                  <div className="select-amount mb-3">
                    <Form.Group controlId="select-amount">
                      <Form.Check
                        inline
                        label={`50 lei`}
                        type="radio"
                        value="50"
                        id="amount-0"
                        name="select-amount"
                        onChange={handleDetalii}
                        checked={donateAmount === "50"}
                      />

                      <Form.Check
                        inline
                        label={`100 lei`}
                        type="radio"
                        value="100"
                        id="amount-1"
                        name="select-amount"
                        onChange={handleDetalii}
                        checked={donateAmount === "100"}
                      />

                      <Form.Check
                        inline
                        label={`200 lei`}
                        type="radio"
                        value="200"
                        id="amount-2"
                        name="select-amount"
                        onChange={handleDetalii}
                        checked={donateAmount === "200"}
                      />
                    </Form.Group>

                    <P>
                      <Span onClick={handleCustomAmount}>
                        Donate any amount
                      </Span>
                    </P>
                  </div>

                  {showDetailsForm && (
                    <div className="details">
                      <Form.Group controlId="amount">
                        <Form.Control
                          name="amount"
                          type="hidden"
                          defaultValue={donateAmount}
                        />
                      </Form.Group>

                      {showCustomAmount && (
                        <Form.Group controlId="custom-amount">
                          <Form.Control
                            name="custom-amount"
                            type="number"
                            placeholder="Enter amount"
                            onChange={handleDonateAmount}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter the amount you want to donate.
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}

                      <Form.Group controlId="recurent">
                        <Form.Label>Recurrent</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue="yes"
                          name="recurent"
                        >
                          <option value="yes" name="yes">
                            Yes
                          </option>
                          <option value="no" name="no">
                            No
                          </option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group controlId="fname">
                        <Form.Control
                          name="fname"
                          type="text"
                          placeholder="First name"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          First name is required.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="lname">
                        <Form.Control
                          name="lname"
                          type="text"
                          placeholder="Last name"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Last name is required.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="email">
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="E-mail"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          E-mail address is invalid.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="phone">
                        <Form.Control
                          name="phone"
                          type="number"
                          placeholder="Phone"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Phone number is required.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button variant="primary" type="submit">
                        Donate
                      </Button>
                    </div>
                  )}
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IndividualsPage;
